import getImageDownloadUrlFromFireBase from "./getImageDownloadUrlFromFireBase";

export interface productList {
  pro_st_nomesite: string;
  pro_in_codigo: string;
  pro_st_sku: string;
  pim_st_imagesrc: string;
  imagens: [
    {
      pim_st_imagesrc: string;
    }
  ];
  estoqueTotal: string;
  pro_de_precopor: string;
  pro_de_precoatacado: string;
  sgp_in_month: number;
}

export interface FormattedProduct {
  id: string;
  nome: string;
  productId: string;
  sku: string;
  imageUrl: string;
  estoque: string;
  precopor: string;
  precoatacado: string;
  month: number;
}

export default async function formatProductsFromApiToListItem(
  products: productList
): Promise<FormattedProduct> {
  return await formatProductList(products);
}

async function formatProductList(product: productList) {
  return {
    id: product.pro_in_codigo.toString(),
    nome: product.pro_st_nomesite,
    productId: product.pro_in_codigo,
    sku: product.pro_st_sku,
    imageUrl:
      product.imagens?.[0]?.pim_st_imagesrc &&
      (await getImageDownloadUrlFromFireBase(
        product.imagens[0].pim_st_imagesrc
      )),
    estoque: product.estoqueTotal,
    precopor: product.pro_de_precopor,
    precoatacado: product.pro_de_precoatacado,
    month: product.sgp_in_month,
  };
}
