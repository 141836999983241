import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../components/Button";
import OrderedTable from "../../components/OrderedTable";
import { getAppWeeklyReport } from "../../services/reportsService";
import { formatNumberToBRL } from "../../utils/formatNumberToBRL";
import { formatWeekRange } from "../../utils/formatWeekRange";
import { getStartAndEndDateFromWeekNumber } from "../../utils/getStartAndEndDateFromWeekNumber";

function handleFormatWeekField(row: number | string) {
  const weekFormated = getStartAndEndDateFromWeekNumber(row?.toString());
  return formatWeekRange(weekFormated?.startDate, weekFormated?.endDate);
}

function formatWeeklyReport({
  report,
}: {
  report: {
    Cadastros: number;
    "Pedidos Aprovado": number;
    "Pedidos Gerados": number;
    "Receita Aprovada": string;
    "Receita Gerada": string;
    Semana: number;
  }[];
}) {
  return report.map((row) => ({
    Semana: row.Semana,
    "Taxa Aprovação Pedidos":
      (row["Pedidos Aprovado"] || 0) / (row["Pedidos Gerados"] || 1),
    "Taxa Aprovação Receita":
      Number(row["Receita Aprovada"] || 0) / Number(row["Receita Gerada"] || 1),
    "Taxa Conversão": (row["Pedidos Aprovado"] || 0) / (row.Cadastros || 0),
    Cadastros: row.Cadastros,
    "Receita Gerada": row["Receita Gerada"],
    "Receita Aprovada": row["Receita Aprovada"],
    "Pedidos Gerados": row["Pedidos Gerados"],
    "Pedidos Aprovados": row["Pedidos Aprovado"],
    "TKM Gerado": Number(row["Receita Gerada"]) / row["Pedidos Gerados"],
    "TKM Aprovado": Number(row["Receita Aprovada"]) / row["Pedidos Aprovado"],
  }));
}

export default function WeeklyReport() {
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const [appWeeklyReport, setAppWeeklyData] = useState<any>([]);

  async function handleGetAppWeeklyReportData() {
    try {
      setIsWaitingApi(true);
      const response = await getAppWeeklyReport();
      setAppWeeklyData(response);
      setIsWaitingApi(false);
    } catch (error) {
      setIsWaitingApi(false);
      console.log(error);
      toast.error("Erro ao buscar resultados");
    }
  }

  useEffect(() => {
    handleGetAppWeeklyReportData();
  }, []);

  return (
    <section className="wrapper">
      <Title>Relatório Semanal do App</Title>
      <DivFlex>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleGetAppWeeklyReportData}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Atualizar"}
          </Button>
        </DivBlock>
      </DivFlex>

      <OrderedTable
        rows={formatWeeklyReport({
          report: appWeeklyReport?.weeklyReport || [],
        }).reverse()}
        rowsFormatting={{
          Semana: handleFormatWeekField,
          "Taxa Aprovação Receita": (row) =>
            `${(Number(row) * 100).toFixed(2)}%`,
          "Taxa Aprovação Pedidos": (row) =>
            `${(Number(row) * 100).toFixed(2)}%`,
          "Receita Gerada": formatNumberToBRL,
          "Receita Aprovada": formatNumberToBRL,
          "TKM Gerado": formatNumberToBRL,
          "TKM Aprovado": formatNumberToBRL,
          "Taxa Conversão": (row) => `${(Number(row) * 100).toFixed(2)}%`,
        }}
      />
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  input {
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
