import { useEffect, useState } from "react";
import { getStrip, updateStrip } from "../../services/stripService";
import styled from "styled-components";

interface Strip {
  isActive: boolean;
  stripMessage: string;
}

export default function Strip() {
  const [formState, setFormState] = useState<Strip | null>(null);

  async function fetchStrip() {
    const strip: Strip = await getStrip();
    setFormState(strip);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (formState) {
      updateStrip(formState?.isActive, formState?.stripMessage);
    }
  };

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    setFormState(
      (prevState) =>
        ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        } as Strip)
    );
  };

  useEffect(() => {
    fetchStrip();
  }, []);

  if (!formState) {
    return <p>Carregando...</p>;
  }

  return (
    <section className="wrapper">
      <PageTitle>Gerenciar Tarja</PageTitle>
      <DisclaimerText>
        IMPORTANTE: A configuração da tarja terá efeito 5 minutos após o
        salvamento, ou seja, tarja irá aparecer/desaparecer ou mudar o texto
        após 5 minutos.
      </DisclaimerText>
      <form onSubmit={handleSubmit}>
        <InputContainer>
          <Label>Ativar/Desativar tarja:</Label>
          <Input
            type="checkbox"
            name="isActive"
            checked={formState.isActive}
            onChange={handleChange}
          />
        </InputContainer>

        <InputContainer>
          <Label>Mensagem da tarja:</Label>
          <Input
            type="text"
            name="stripMessage"
            value={formState.stripMessage}
            onChange={handleChange}
          />
        </InputContainer>

        <ButtonContainer>
          <Button type="submit">Salvar configuração</Button>
        </ButtonContainer>
      </form>
    </section>
  );
}

export const InputContainer = styled.div`
  display: flex;
  width: 750px;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Label = styled.p`
  font-size: 1.25rem;
  display: inline;
  margin-right: 1rem;
`;

const Input = styled.input`
  width: ${(props) => (props.type === "text" ? "300px" : "35px")};
  border-radius: 7px;
  border: 1px solid #85b074;
  font-size: 1rem;
  padding: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
  text-align: flex-start;
`;

const PageTitle = styled.h1`
  margin-bottom: 28px;
  margin-top: 48px;
`;

const DisclaimerText = styled.p`
  font-size: 0.75rem;
  color: red;
  margin-bottom: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 750px;
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 1rem;
`;
