import { useState } from "react";
import styled from "styled-components";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FormattedProduct } from "../utils/formatProductsFromApiToListItem";
import Button from "./Button";

export default function ProductsOrderManager({
  products,
  setProducts,
}: {
  products: FormattedProduct[];
  setProducts: React.Dispatch<React.SetStateAction<FormattedProduct[]>>;
}) {
  const [activeId, setActiveId] = useState<string | null>(null);

  const activeProduct =
    activeId != null ? products.find((p) => p.productId === activeId) : null;

  const handleRemove = (id: string) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.productId !== id)
    );
  };

  const handleSendToTop = (id: string) => {
    setProducts((prevProducts) => {
      const product = prevProducts.find((prod) => prod.productId === id);
      if (!product) return prevProducts;
      return [product].concat(
        prevProducts.filter((product) => product.productId !== id)
      );
    });
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={(event) => {
        setActiveId(event.active.id as string);
      }}
      onDragEnd={(event) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
          setProducts((items: FormattedProduct[]) => {
            const oldIndex = items.findIndex(
              (item) => item.productId === active.id
            );
            const newIndex = items.findIndex(
              (item) => item.productId === over?.id
            );

            return arrayMove(items, oldIndex, newIndex) as FormattedProduct[];
          });
        }

        setActiveId(null);
      }}
    >
      <SortableContext
        items={products.map((p) => p.productId)}
        strategy={rectSortingStrategy}
      >
        <DisplayGrid>
          {products.map((product) => (
            <SortableProductItem
              key={product.productId}
              id={product.productId}
              product={product}
              activeId={activeId}
              onRemove={handleRemove}
              onSendToTop={handleSendToTop}
            />
          ))}
        </DisplayGrid>
      </SortableContext>

      <DragOverlay
        dropAnimation={{
          duration: 200,
          easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
        }}
      >
        {activeProduct ? (
          <ProductCard>
            <ProductImg src={activeProduct.imageUrl} alt={activeProduct.nome} />
            <strong>{activeProduct.nome}</strong>
          </ProductCard>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}

function SortableProductItem({
  id,
  product,
  activeId,
  onRemove,
  onSendToTop,
}: {
  id: string;
  product: FormattedProduct;
  activeId: string | null;
  onRemove: (id: string) => void;
  onSendToTop: (id: string) => void;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ProductCard $isFaded={id === activeId}>
        <ProductImg src={product.imageUrl} alt={product.nome} />
        <strong>{product.nome}</strong>
        <Button
          className="send-to-top__button"
          variant="light"
          onClick={(e) => {
            e.stopPropagation();
            onSendToTop(id);
          }}
          onPointerDown={(e) => e.stopPropagation()}
        >
          Enviar ao topo
        </Button>
        <Button
          className="remove__button"
          variant="light"
          onClick={(e) => {
            e.stopPropagation();
            onRemove(id);
          }}
          onPointerDown={(e) => e.stopPropagation()}
        >
          Remover
        </Button>
      </ProductCard>
    </div>
  );
}

const ProductCard = styled.div<{ $isFaded?: boolean }>`
  cursor: grab;
  opacity: ${(props) => (props.$isFaded ? "0.33" : "1")};
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #85b074;
  padding: 0.5rem;
  margin: 0.5rem;
  strong {
    color: rgb(59, 59, 58);
    font-size: 12px;
    line-height: 12px;
    height: 36px;
    width: 120px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 700;
    letter-spacing: 0.02em;
    word-break: break-word;
    text-align: left;
  }

  .send-to-top__button,
  .remove__button {
    border-radius: 5px;
    height: 24px;
    font-size: 12px;
    width: 100%;
    color: #fff;
  }

  .send-to-top__button {
    padding: 0;
  }

  .remove__button {
    background-color: red;
    margin-top: 0.5rem;
  }
`;
const ProductImg = styled.img`
  width: 120px;
  height: 120px;
`;

const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10;
  padding: 10;
`;
