import { CSVLink } from "react-csv";
import { useState, useEffect } from "react";
import Papa from "papaparse";
import styled from "styled-components";
import { createCollection } from "../services/collectionService";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../services/productsService";
import { formatPrice } from "../utils/price";

const templateHeader = [
  { label: "SKU Millennium", key: "productSku" },
  { label: "Ordem do produto", key: "productOrder" },
  { label: "Id do produto (opcional)", key: "productId" },
  { label: "Nome do produto (opcional)", key: "productName" },
];

const templateData = [
  {
    productSku: "cve00001",
    productId: "10",
    productName: `nome produto ${1}`,
    productOrder: 1,
  },
  {
    productSku: "cve00002",
    productId: "20",
    productName: `nome produto ${2}`,
    productOrder: 2,
  },
  {
    productSku: "cve00003",
    productId: "30",
    productName: `nome produto ${3}`,
    productOrder: 3,
  },
  {
    productSku: "cve00004",
    productId: "40",
    productName: `nome produto ${4}`,
    productOrder: 4,
  },
  {
    productSku: "cve00005",
    productId: "50",
    productName: `nome produto ${5}`,
    productOrder: 5,
  },
];

function Clusters() {
  const [name, setName] = useState<any>("");
  const [url, setUrl] = useState<string>("");
  const [tableRows, setTableRows] = useState<any>([]);
  const [values, setValues] = useState<any>([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const changeHandler = (event: any) => {
    setLoading(true);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        const rowsArray: any = [];
        const valuesArray: any = [];

        const promises = results.data.map((d: any) => {
          return getProducts({ sku: d[Object.keys(d)[0]] });
        });

        const productsData = await Promise.all(promises);

        results.data.forEach((d: any, index: number) => {
          const precopor = Number(productsData[index].precopor);
          const precocaixa = Number(productsData[index].precoatacado);

          const rowsData = [
            ...Object.keys(d),
            "Estoque",
            "Preço unidade",
            "Preço caixa",
          ];

          const valuesData = [
            ...Object.values(d),
            Number(productsData[index].estoque),
            precopor > 0 ? formatPrice(precopor) : null,
            precocaixa > 0 ? formatPrice(precocaixa) : null,
          ];

          rowsArray.push(rowsData);
          valuesArray.push(valuesData);
        });

        setTableRows(rowsArray[0]);
        setValues(valuesArray);
      },
    });
  };

  const handleSubmit = async () => {
    setLoading(true);

    await createCollection({
      clu_st_nome: name,
      clu_st_url: url,
      products: values.map((val: any) => ({
        pro_st_sku: val[0],
        clp_in_ordem: val[1],
        pro_in_codigo: Number(val[2]),
      })),
    });
    navigate("/clustersList");

    setLoading(false);
  };

  useEffect(() => {
    if (name === "" || values.length < 1 || url === "" || url.length < 1)
      return setDisabled(true);

    setDisabled(false);
  }, [name, values, url]);

  useEffect(() => {
    if (values.length > 0) {
      setLoading(false);
    }
  }, [values]);

  return (
    <section className="wrapper">
      <Container>
        <Header>
          <TemplateButton
            data={templateData}
            headers={templateHeader}
            filename={"template_colecoes.csv"}
          >
            Download template
          </TemplateButton>
        </Header>

        {loading ? (
          <section className="wrapper">
            <div>carregando...</div>
          </section>
        ) : (
          <>
            <InputContainer>
              <FileInput id="arquivo" onChange={changeHandler} />
              <Info>
                Obs: os produtos aparecerão na ordem das linhas do excel
              </Info>
            </InputContainer>

            {tableRows.length > 0 && (
              <div>
                <ContainerInputs>
                  <FileInputContainer>
                    <FileLabel>* Insira o nome da coleção: </FileLabel>
                    <NameInput
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FileInputContainer>
                  <FileInputContainer>
                    <FileLabel>* Insira a url da coleção: </FileLabel>
                    <NameInput
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                  </FileInputContainer>
                </ContainerInputs>

                <br />
                <br />

                <Table>
                  <Row>
                    <HeaderCell>Posição</HeaderCell>
                    {tableRows.map((rows: [], index: number) => (
                      <HeaderCell key={index}>{rows}</HeaderCell>
                    ))}
                  </Row>

                  {values.map((value: [], index: number) => (
                    <Row key={index}>
                      <Cell>{index + 1}</Cell>

                      {value.map((val: [], i: number) => (
                        <Cell key={i}>{val}</Cell>
                      ))}
                    </Row>
                  ))}
                </Table>

                <br />
                <br />
                <br />

                <ButtonContainer>
                  <Button disabled={disabled} onClick={() => handleSubmit()}>
                    ENVIAR
                  </Button>
                </ButtonContainer>
              </div>
            )}
          </>
        )}
      </Container>
    </section>
  );
}

export default Clusters;

const Container = styled.div`
  width: 100%;
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 2rem;
  font-size: 1em;
  align-items: flex-end;
`;

const TemplateButton = styled(CSVLink)`
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  cursor: pointer;
`;

const InputContainer = styled.div`
  margin-bottom: 2rem;
`;

const Info = styled.p`
  font-size: 1rem;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
`;

const FileInputContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  align-items: center;
`;

const FileLabel = styled.p`
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
`;

const Table = styled.table`
  width: 60%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
  margin: 0 auto;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1.5rem;
  background-color: ${(props) => (props.disabled ? "#a7a7a7" : "#85b074")};
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  cursor: ${(props) => (props.disabled ? "normal" : "pointer")};
`;

const FileInput = styled.input.attrs({
  type: "file",
  accept: ".csv",
})`
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 0.25rem;
`;

const NameInput = styled.input`
  border-radius: 7px;
  border: 1px solid #85b074;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;

  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
`;

const ContainerInputs = styled.div`
  width: 500px;
  height: 100%;
`;
