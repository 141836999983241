import styled from "styled-components";
import { FormattedProduct } from "../utils/formatProductsFromApiToListItem";
import Button from "./Button";

export default function ProductListPicker({
  onProductSelected,
  productList,
}: {
  onProductSelected: (product?: FormattedProduct) => void;
  productList: FormattedProduct[];
}) {
  return (
    <DisplayGrid>
      {productList.map((product) => (
        <ProductPickerCard
          key={product.productId}
          product={product}
          addProduct={onProductSelected}
        />
      ))}
    </DisplayGrid>
  );
}

function ProductPickerCard({
  product,
  addProduct,
}: {
  product: FormattedProduct;
  addProduct: (product?: FormattedProduct) => void;
}) {
  return (
    <ProductCard>
      <ProductImg src={product.imageUrl} alt={product.nome} />
      <strong>{product.nome}</strong>
      <Button
        variant="light"
        onClick={(e) => {
          e.stopPropagation();
          addProduct(product);
        }}
        onPointerDown={(e) => e.stopPropagation()}
      >
        Adicionar
      </Button>
    </ProductCard>
  );
}

const ProductCard = styled.div`
  cursor: grab;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #85b074;
  padding: 0.5rem;
  margin: 0.5rem;
  strong {
    color: rgb(59, 59, 58);
    font-size: 12px;
    line-height: 12px;
    height: 36px;
    width: 120px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 700;
    letter-spacing: 0.02em;
    word-break: break-word;
    text-align: left;
  }

  button {
    border-radius: 5px;
    height: 24px;
    font-size: 12px;
    width: 100%;
    color: #fff;
  }
`;
const ProductImg = styled.img`
  width: 120px;
  height: 120px;
`;

const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10;
  padding: 10;
`;
