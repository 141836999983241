import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Banners from "./pages/Banners";
import Login from "./pages/Login";
import AuthRoutes from "./components/AuthRoutes";
import Authenticator from "./pages/Authenticator";
import jwtManager from "./utils/jwtManager";
import Header from "./components/Header";
import GlobalStyle from "./styles/global";
import { ThemeProvider } from "styled-components";
import colors from "./styles/colors";
import Clusters from "./pages/Clusters";
import Coupons from "./pages/Coupons";
import OrderList from "./pages/OrderList";
import OrderDetails from "./pages/OrderDetails";
import Reports from "./pages/Reports";
import CoupomList from "./pages/CoupomList";
import EditCoupom from "./pages/EditCoupom";
import SearchReports from "./pages/SearchReports";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import ClusterList from "./pages/ClustersList";
import ClusterDetails from "./pages/ClusterDetails";
import Freight from "./pages/Freight";
import CategoriesOrderBy from "./pages/CategoriesOrderBy";
import CategoryList from "./pages/CategoriesList";
import ProductRestrictions from "./pages/ProductRestrictions";
import RestrictionItem from "./pages/ProductRestrictions/RestrictionItem";
import RestrictionNew from "./pages/ProductRestrictions/RestrictionNew";
import AppOrdersErrorsLogs from "./pages/AppOrdersErrorsLogs";
import { SignatureDashboard } from "./pages/SignatureDashboard";
import DictionaryList from "./pages/Dictionary";
import ProductsDashboard from "./pages/ProductsDashboard";
import AppGeneralDashboard from "./pages/AppGeneralDashboard";
import PromotionalProducts from "./pages/PromotionalProduct";
import AppExternalReports from "./pages/AppExternalReports";
import AppPerformanceReport from "./pages/AppPerformanceReport";
import ShippingReport from "./pages/ShippingReport";
import PaymentsReport from "./pages/PaymentsReport";
import ClientsReport from "./pages/ClientsReport";
import ShippingPromotions from "./pages/ShippingPromotions";
import PromotionShipping from "./pages/ShippingPromotions/PromotionShipping";
import WeeklyReport from "./pages/WeeklyReport";
import SignatureApp from "./pages/SignatureApp";
import FormCreatePlan from "./pages/SignatureApp/FormCreatePlan";
import SelectProduct from "./pages/SignatureApp/SelectProducts";
import Kits from "./pages/Kits";
import CreateKit from "./pages/Kits/CreateKit";
import EditKit from "./pages/Kits/EditKit";
import GenerateLink from "./pages/GenerateLink";
import SellerCenter from "./pages/SellerCenter";
import OrderListSplitted from "./pages/OrderListSplitted";
import OrderSplittedDetails from "./pages/OrderSplittedDetails";
import SalesDashboard from "./pages/SalesDashboard";
import ClientCarts from "./pages/ClientCarts";
import ClientList from "./pages/ClientList";
import CategoriesProductsOrder from "./pages/CategoriesProductsOrder";
import Strip from "./pages/Strip";

const App = () => {
  jwtManager.loadTokens();

  const emailsCX = [
    "rafaela.nascimento@sonoma.com.br",
    "cleciane.oliveira@sonoma.com.br",
    "weslei.andrade@sonoma.com.br",
    "rarime.oliveira@sonoma.com.br",
    "giovanna.silva@sonoma.com.br",
    "isabela.lima@sonoma.com.br",
  ];

  const storedEmail = localStorage.getItem("Email");

  return (
    <ThemeProvider theme={colors}>
      <GlobalStyle />
      {storedEmail && emailsCX.includes(storedEmail) ? (
        <Router>
          <Header />
          <Routes>
            <Route path="/cx/login" element={<Login />} />
            <Route path="/cx/authenticate" element={<Authenticator />} />
            <Route element={<AuthRoutes />}>
              <Route element={<CoupomList />} path="/cx/couponsList" />
              <Route element={<OrderList />} path="/cx/orders" />
              <Route element={<OrderListSplitted />} path="/splitted" />
              <Route element={<OrderDetails />} path="/cx/order/:id" />
              <Route
                element={<OrderSplittedDetails />}
                path="/order/splitted/:id"
              />
              <Route element={<Freight />} path="/cx/freight" />
            </Route>
            <Route path="/cx/*" element={<Login />} />
          </Routes>
        </Router>
      ) : (
        <Router>
          <Header />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/authenticate" element={<Authenticator />} />
            <Route element={<AuthRoutes />}>
              <Route element={<Dashboard />} path="/dashboard" />
              <Route element={<Banners />} path="/banners" />
              <Route element={<Banners />} path="/audit" />
              <Route element={<Coupons />} path="/coupons" />
              <Route element={<CoupomList />} path="/couponsList" />
              <Route element={<CategoryList />} path="/categories" />
              <Route element={<CategoriesOrderBy />} path="/category/:id" />
              <Route element={<CategoriesOrderBy />} path="/category" />
              <Route
                element={<CategoriesProductsOrder />}
                path="/categories/products/order"
              />
              <Route element={<Banners />} path="/debug" />
              <Route element={<Clusters />} path="/clusters" />
              <Route element={<ClusterList />} path="/clustersList" />
              <Route element={<ClusterDetails />} path="/clustersDetails/:id" />
              <Route element={<OrderList />} path="/orders" />
              <Route element={<OrderListSplitted />} path="/splitted" />
              <Route
                element={<OrderSplittedDetails />}
                path="/order/splitted/:id"
              />
              <Route element={<OrderDetails />} path="/order/:id" />
              <Route element={<EditCoupom />} path="/editCoupons/:id" />
              <Route element={<Reports />} path="/reports/orders" />
              <Route element={<SearchReports />} path="/reports/search" />
              <Route element={<SignatureApp />} path="/signature" />
              <Route element={<GenerateLink />} path="/createLink" />
              <Route element={<FormCreatePlan />} path="/signature/create" />
              <Route
                element={<SelectProduct />}
                path="/signature/selectProducts/:id"
              />
              <Route
                element={<SignatureDashboard />}
                path="/reports/signature"
              />
              <Route
                element={<ProductsDashboard />}
                path="/reports/bestsellers"
              />
              <Route
                element={<AppGeneralDashboard />}
                path="/reports/general"
              />
              <Route element={<AppPerformanceReport />} path="/reports/app" />
              <Route element={<ShippingReport />} path="/reports/shipping" />
              <Route element={<PaymentsReport />} path="/reports/payment" />
              <Route element={<ClientsReport />} path="/reports/clients" />
              <Route
                element={<AppExternalReports />}
                path="/reports/external"
              />
              <Route element={<WeeklyReport />} path="/reports/weekly" />
              <Route element={<Freight />} path="/freight" />
              <Route element={<ProductRestrictions />} path="/restrictions" />
              <Route element={<RestrictionItem />} path="/restriction/:id" />
              <Route element={<RestrictionNew />} path="/restriction" />
              <Route element={<AppOrdersErrorsLogs />} path="/audit/orders" />
              <Route
                element={<PromotionalProducts />}
                path="/promotional_products"
              />
              <Route element={<DictionaryList />} path="/dictionary" />
              <Route
                element={<ShippingPromotions />}
                path="/promotions/shipping/all"
              />
              <Route
                element={<PromotionShipping />}
                path="/promotions/shipping/:id"
              />
              <Route
                element={<PromotionShipping />}
                path="/promotions/shipping"
              />
              <Route element={<CreateKit />} path="/kits/create" />
              <Route element={<EditKit />} path="/kits/:id" />
              <Route element={<Kits />} path="/kits" />
              <Route element={<SellerCenter />} path="/sellerCenter" />
              <Route element={<SalesDashboard />} path="/sales/dashboard" />
              <Route element={<ClientCarts />} path="/client/carts" />
              <Route element={<ClientList />} path="/clients" />
              <Route element={<Strip />} path="/strip" />
            </Route>
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      )}
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
