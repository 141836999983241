import React from "react";
import { ContainerActions, Main, Select } from "./style";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  resultsTotal: number;
  itemsPerPageOptions: number[];
  setCurrentPage: (page: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  itemsPerPageOptions,
  currentPage,
  resultsTotal,
  itemsPerPage,
  setCurrentPage,
  onItemsPerPageChange,
}) => {
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    onItemsPerPageChange(newItemsPerPage);
    setCurrentPage(1);
  };

  return (
    <Main>
      <div>
        <span>Items por página: </span>
        <Select value={itemsPerPage} onChange={handleItemsPerPageChange}>
          {itemsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </div>
      <ContainerActions>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Próxima
        </button>
      </ContainerActions>
      <p>Total: {resultsTotal}</p>
    </Main>
  );
};

export default Pagination;
