import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FormFilePicker from "../../components/BannerManager/FormFilePicker";
import FormInput from "../../components/BannerManager/FormInput";
import FormTextArea from "../../components/BannerManager/FormTextArea";
import Button from "../../components/Button";
import Papa from "papaparse";
import { TemplateButton } from "../ProductRestrictions/RestrictionItem";
import { mockData, mockHeaders } from "./mockData";
import OrderedTable from "../../components/OrderedTable";
import { toast } from "react-toastify";
import { createKit } from "../../services/kitsService";
import { useNavigate } from "react-router-dom";
import ProductFinderHelper from "./ProductFinderHelper";

export default function CreateKit() {
  const [step, setStep] = useState<Number>(0);
  const [kitName, setkitName] = useState<string>("");
  const [kitUrl, setKitUrl] = useState<string>("");
  const [kitDescription, setkitDescription] = useState<string>("");
  const [kitAbout, setkitAbout] = useState<string>("");
  const [kitHistory, setkitHistory] = useState<string>("");
  const [preview, setPreview] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [newProducts, setNewProducts] = useState<any>([]);
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const file = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const changeHandler = (event: any) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setNewProducts(results.data);
      },
    });
  };

  const handleSetSingleProduct = (product: any) => {
    setNewProducts([
      ...newProducts,
      {
        "Sku Mln": product.sku,
        Quantidade: product.Quantidade,
        "Nome do produto (opcional)": product.nome,
      },
    ]);
  };

  const handleCreateKit = async () => {
    setIsWaitingApi(true);
    try {
      if (!selectedFile) throw new Error("Erro ao carregar a imagem.");

      if (!kitUrl.length) setKitUrl(kitName.replace(/ /g, "-").toLowerCase());

      const res = await createKit({
        kitName,
        kitUrl,
        kitDescription,
        kitAbout,
        kitHistory,
        newProducts,
        image: selectedFile,
      });

      if (res.status === 201) {
        toast.success("Kit criado com sucesso!");
        navigate("/kits");
      }
    } catch (error) {
      console.log(error);

      toast.error(`Erro ao criar Kit: \n${error}`);
    }
    setIsWaitingApi(false);
  };

  return (
    <section className="wrapper">
      <Title>Criação de Kits</Title>
      {step === 0 ? (
        <>
          <SubTitle>Passo 1: Preencha as informações do Kit</SubTitle>
          <Form>
            <FormInput
              title="Nome do Kit"
              placeholder="Digite um nome aqui"
              setValue={setkitName}
              type="text"
              value={kitName}
              required
              width="300px"
              height="2rem"
              borderRadius="2px"
            />
            <FormInput
              title="Url: EX(kit-quinteto-misto-maiora)"
              placeholder="Digite a url do kit aqui"
              setValue={setKitUrl}
              type="text"
              value={kitUrl}
              required
              width="300px"
              height="2rem"
              borderRadius="2px"
            />
            <FormTextArea
              title="Descrição do Kit"
              placeholder="Digite uma descrição aqui"
              setValue={setkitDescription}
              type="text"
              value={kitDescription}
              required
              width="300px"
              height="6rem"
              aria-multiline="true"
            />
            <FormTextArea
              title="Sobre o Produto"
              placeholder="Digite uma descrição aqui"
              setValue={setkitAbout}
              type="text"
              value={kitAbout}
              required
              width="300px"
              height="6rem"
              aria-multiline="true"
            />
            <FormTextArea
              title="História do Kit"
              placeholder="Digite uma descrição aqui"
              setValue={setkitHistory}
              type="text"
              value={kitHistory}
              required
              width="300px"
              height="6rem"
              aria-multiline="true"
            />
            <FormFilePicker
              required={true}
              title="Escolha a imagem do kit"
              placeholder="Digite uma URL da web aqui"
              type="file"
              accept="image/jpeg"
              inputRef={file}
              onChange={onSelectFile}
            />
            <ImgContainer>
              {preview && <img src={preview} alt={"Imagem Kit"} />}
            </ImgContainer>
            <Button
              variant="light"
              onClick={() => setStep(1)}
              disabled={!kitName || !kitDescription || !kitHistory || !preview}
            >
              Continuar
            </Button>
          </Form>
        </>
      ) : (
        <>
          <SubTitle>Passo 2: Adicione os produtos do Kit</SubTitle>
          <ProductFinderHelper setProduct={handleSetSingleProduct} />
          <DivFlex>
            <DivBlock>
              <TemplateButton
                data={mockData}
                headers={mockHeaders}
                filename={"template_produtos_kits.csv"}
                variant="light"
              >
                Baixar template
              </TemplateButton>
            </DivBlock>
            <DivBlock>
              <FileInput id="file" onChange={changeHandler} />
            </DivBlock>
            <DivBlock>
              <Button
                variant="light"
                onClick={() => setStep(0)}
                disabled={isWaitingApi}
              >
                Voltar
              </Button>
            </DivBlock>
          </DivFlex>
          {newProducts && (
            <DivFlex>
              <DivBlock>
                <DivFlex>
                  <DivBlock>
                    <strong>Itens a serem incluídos:</strong>
                    <small>
                      Pressione o botão `Concluir` para criar o Kit.
                    </small>
                  </DivBlock>
                  <DivBlock>
                    <Button
                      variant="light"
                      onClick={handleCreateKit}
                      disabled={isWaitingApi}
                    >
                      {`${isWaitingApi ? "Carregando..." : "Concluir"}`}
                    </Button>
                  </DivBlock>
                </DivFlex>

                <OrderedTable rows={newProducts} />
              </DivBlock>
            </DivFlex>
          )}
        </>
      )}
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1rem;
  font-weight: 400;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 4rem;

  strong {
    font-size: 1.5rem;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-top: 1rem;
  background-color: #fff;
  padding: 2px;
  width: 200px;
  height: 200px;

  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
`;

const FileInput = styled.input.attrs({
  type: "file",
  accept: ".csv",
})`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  border: none;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  height: 2.5rem;
  padding: 0.25rem 2rem;
  cursor: pointer;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text_light};
  font-weight: 600;
  width: max-content;

  &:disabled {
    cursor: unset;
    opacity: 80%;
  }

  &::file-selector-button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text_light};
    font-weight: 600;
    width: max-content;
  }
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }

  strong {
    margin-top: 2rem;
  }

  table {
    margin-top: 1rem;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
