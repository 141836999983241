import { Dispatch, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import { getProducts } from "../../services/productsService";

export default function ProductFinderHelper({
  setProduct,
  showQuantityInput = true,
}: {
  setProduct: Dispatch<any>;
  showQuantityInput?: boolean;
}) {
  const [productSku, setproductSku] = useState<string>("");
  const [productAmount, setproductAmount] = useState<number>(0);
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const handleFetchProduct = async () => {
    setIsWaitingApi(true);
    try {
      const product = await getProducts({ sku: productSku });

      if (product.sku)
        setProduct({
          ...product,
          ...(!!showQuantityInput ? { Quantidade: productAmount } : {}),
        });
    } catch (error) {
      toast.warn(`Nenhum produto encontrado com SKU: ${productSku}`);
    }
    setIsWaitingApi(false);
  };

  return (
    <DivBlock>
      <DivFlex>
        <FormInput
          title="Digite o Sku do produto"
          placeholder="Digite um SKU aqui"
          setValue={setproductSku}
          type="text"
          value={productSku}
          required
          width="300px"
          height="2rem"
          borderRadius="2px"
        />
        <Button
          variant="dark"
          onClick={handleFetchProduct}
          disabled={isWaitingApi || productSku.length < 3}
        >
          {`${isWaitingApi ? "Carregando" : "Incluir Produto"}`}
        </Button>
      </DivFlex>
      {!!showQuantityInput && (
        <DivFlex>
          <FormInput
            title="Digite a quantidade do produto"
            placeholder="Digite a quantidade aqui"
            setValue={setproductAmount}
            type="text"
            value={productAmount}
            required
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivFlex>
      )}
    </DivBlock>
  );
}

const DivFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;

  button {
    margin-top: 0;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }

  strong {
    margin-top: 2rem;
  }

  table {
    margin-top: 1rem;
  }
`;
