import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";

export async function getKits() {
  try {
    const kits = await axios.get(`${configs.appApiBaseUrl}/kits`);
    return kits;
  } catch (error) {
    toast.error(`Erro ao tentar carregar kits \n", ${error}`);
  }
}

export async function getKit({ id }: { id: string }) {
  try {
    const kit = await axios.get(`${configs.appApiBaseUrl}/kits/${id}`);
    return kit;
  } catch (error) {
    toast.error(`Erro ao tentar carregar kit \n", ${error}`);
  }
}

export async function createKit({
  kitName,
  kitUrl,
  kitDescription,
  kitAbout,
  kitHistory,
  newProducts,
  image,
}: {
  kitName: string;
  kitUrl: string;
  kitDescription: string;
  kitAbout: string;
  kitHistory: string;
  newProducts: any[];
  image: File;
}) {
  try {
    const formData = new FormData();

    formData.append("file", image, image.name);
    formData.append("kitName", kitName);
    formData.append("kitUrl", kitUrl);
    formData.append("kitDescription", kitDescription);
    formData.append("kitAbout", kitAbout);
    formData.append("kitHistory", kitHistory);
    formData.append("newProducts", JSON.stringify(newProducts));

    return await axios.post(`${configs.apiBaseUrl}/api/kits/create`, formData);
  } catch (error) {
    console.log(error);

    throw error;
  }
}

export async function updateKit({
  id,
  kitName,
  kitUrl,
  kitDescription,
  kitAbout,
  kitHistory,
  newProducts,
  image,
  isActive,
}: {
  id: string;
  kitName: string;
  kitUrl: string;
  kitDescription: string;
  kitAbout: string;
  kitHistory: string;
  newProducts: any[];
  isActive: boolean;
  image?: File;
}) {
  try {
    const formData = new FormData();

    if (image) formData.append("file", image, image.name);
    formData.append("id", id);
    formData.append("kitName", kitName);
    formData.append("kitUrl", kitUrl);
    formData.append("kitDescription", kitDescription);
    formData.append("kitAbout", kitAbout);
    formData.append("kitHistory", kitHistory);
    formData.append("kitIsActive", isActive.toString());
    formData.append("newProducts", JSON.stringify(newProducts));

    return await axios.put(`${configs.apiBaseUrl}/api/kits/update`, formData);
  } catch (error) {
    console.log(error);

    throw error;
  }
}

export async function kitAddProductsRelateds({
  products,
  kitId,
}: {
  kitId: string;
  products: { sku: string }[];
}) {
  try {
    await axios.post(`${configs.apiBaseUrl}/api/kits/addproductsrelated`, {
      products,
      kitId,
    });
    toast.success("Produtos relacionados com sucesso");
  } catch (error: any) {
    toast.error("Erro ao adicionar produtos relacionados ao kit");
    throw error;
  }
}

export async function removeProductRelated({
  product,
  kitId,
}: {
  kitId: string;
  product: string;
}) {
  try {
    await axios.post(`${configs.apiBaseUrl}/api/kits/removeproductrelated`, {
      product,
      kitId,
    });
    toast.success("Produtos relacionados com sucesso");
  } catch (error: any) {
    toast.error("Erro ao adicionar produtos relacionados ao kit");
    throw error;
  }
}
