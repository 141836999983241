import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";

export async function getPedidos({
  limit,
  page,
  ref,
  minDate,
  maxDate,
  status,
}: {
  limit: number;
  page: number;
  ref?: string;
  minDate: string;
  maxDate: string;
  status: string;
}): Promise<any> {
  try {
    const pedidos = await axios.get(
      `${configs.apiBaseUrl}/api/pedidos/app?ref=${ref}&minDate=${minDate}&maxDate=${maxDate}&page=${page}&limit=${limit}&status=${status}`
    );

    return {
      pedidos: pedidos.data.pedidos,
      pages: pedidos.data.pages,
      total: pedidos.data.total,
    };
  } catch (error) {
    toast.error(`Erro ao tentar trazer pedidos \n", ${error}`);
  }
}

export async function getPedidosSplitted(
  limit: number,
  ref?: string,
  minDate?: string,
  maxDate?: string
) {
  try {
    // remove pagination
    const PAGE = 1;

    const pedidos = await axios.get(
      `${configs.apiBaseUrl}/api/pagarme/payments/app?ref=${ref}&minDate=${minDate}&maxDate=${maxDate}&page=${PAGE}&limit=${limit}`
    );

    return pedidos.data.result;
  } catch (error) {
    toast.error(`Erro ao tentar trazer pedidos \n", ${error}`);
    console.log("ERROR ON GET ORDERS", error);
  }
}

export async function getPedidoById(codPedido: any) {
  try {
    const pedidos = await axios.get(
      `${configs.apiBaseUrl}/api/pedidos/app/${codPedido}`
    );

    return pedidos.data.pedidos;
  } catch (error) {
    console.log("ERROR ON GET ORDER", error);
  }
}

export async function getPedidoSplittedById(codPedido: any) {
  try {
    const pedidos = await axios.get(
      `${configs.apiBaseUrl}/api/pagarme/payment/${codPedido}`
    );

    return pedidos.data.result;
  } catch (error) {
    console.log("ERROR ON GET ORDER", error);
  }
}

export async function updateOrderStatus(codPedido: string) {
  try {
    const result = await axios.put(
      `${configs.apiBaseUrl}/api/pedidos/app/${codPedido}`
    );

    toast.success(result.data.message);
  } catch (error: any) {
    toast.error(error.message);
  }
}
