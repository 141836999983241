import React, { useState } from "react";
import styled from "styled-components";
import ProductFinderHelper from "../../pages/Kits/ProductFinderHelper";
import Button from "../Button";
import OrderedTable from "../OrderedTable";
import { toast } from "react-toastify";
import { formatNumberToBRL } from "../../utils/formatNumberToBRL";
import {
  kitAddProductsRelateds,
  removeProductRelated,
} from "../../services/kitsService";

export default function KitRelatedProduct({
  kitRelatedProducts,
  kitId,
}: {
  kitRelatedProducts: any[];
  kitId?: string;
}) {
  const [newProductsRelated, setNewProductsRelated] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSetSingleProduct = (product: any) => {
    setNewProductsRelated((prevState) => {
      if (prevState.find((prod: any) => prod["Sku Mln"] === product.sku)) {
        toast.warn("Produto já adicionado");
        return prevState;
      }

      return [
        ...prevState,
        { "Sku Mln": product.sku, "Nome do produto (opcional)": product.nome },
      ];
    });
  };

  const handleAddProductsRelated = async () => {
    const mappingProductsRelated = newProductsRelated.map((prod) => ({
      sku: prod["Sku Mln"],
    }));

    try {
      setLoading((prevState) => !prevState);
      await kitAddProductsRelateds({
        products: mappingProductsRelated,
        kitId: kitId!,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prevState) => !prevState);
      window.location.reload();
    }
  };

  const handleRemoveProductsRelated = async ({
    product,
  }: {
    product: string;
  }) => {
    try {
      setLoading((prevState) => !prevState);
      await removeProductRelated({ product, kitId: kitId! });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prevState) => !prevState);
      window.location.reload();
    }
  };

  return (
    <DivBlock>
      <hr />
      <Title>Adicionar Produto Relacionado</Title>
      <SubTitle>Adicione produto relacionado</SubTitle>
      <ProductFinderHelper
        setProduct={handleSetSingleProduct}
        showQuantityInput={false}
      />
      {!!newProductsRelated.length && (
        <DivFlex>
          <DivBlock>
            <DivFlex>
              <DivBlock>
                <strong>Itens a serem incluídos:</strong>
                <small>
                  Pressione o botão "Adicionar" para adicionar os produtos
                  relacionados Kit.
                </small>
              </DivBlock>
              <DivBlock>
                <Button
                  variant="light"
                  onClick={handleAddProductsRelated}
                  disabled={loading}
                >
                  {`${loading ? "Carregando..." : "Adicionar"}`}
                </Button>
              </DivBlock>
            </DivFlex>

            <OrderedTable rows={newProductsRelated} />
          </DivBlock>
        </DivFlex>
      )}
      <DivBlock>
        <SubTitle>Produtos Relacionados ao KIT</SubTitle>
        <OrderedTable
          rows={kitRelatedProducts?.map((item: any) => ({
            ...item,
            Remover: (
              <Button
                variant="light"
                onClick={() =>
                  handleRemoveProductsRelated({ product: item["Sku Mln"] })
                }
              >
                {`${loading ? "Removendo..." : "Remover"}`}
              </Button>
            ),
          }))}
          rowsFormatting={{
            "Preço Por": formatNumberToBRL,
            "Preço De": formatNumberToBRL,
            "Preço Atacado": formatNumberToBRL,
          }}
        ></OrderedTable>
      </DivBlock>
    </DivBlock>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1rem;
  font-weight: 700;
  margin-top: 10px;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }

  strong {
    margin-top: 2rem;
  }

  table {
    margin-top: 1rem;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
