import axios from "axios";
import configs from "../appConfigs";
import { toast } from "react-toastify";

export async function getStrip() {
  try {
    const strip = await axios.get(`${configs.apiBaseUrl}/api/strip/findOne`);

    return strip.data;
  } catch (error) {
    console.error(error);
  }
}

export async function updateStrip(stripToggle: boolean, stripMessage: string) {
  try {
    await axios.put(`${configs.apiBaseUrl}/api/strip`, {
      stripToggle,
      stripMessage,
    });
    toast.success("Tarja atualizada com sucesso!");
  } catch (error) {
    console.error(error);
    toast.error("Erro ao atualizar tarja");
  }
}
