import styled from "styled-components";
import { useEffect, useState } from "react";
import { createCoupon } from "../services/couponService";
import DatePicker from "../components/DatePicker";
import ClusterSelect from "../components/ClusterSelect";

const coupomTypes = {
  percent: 0,
  fixed: 1,
  freight: 2,
};

export default function Coupons() {
  const [name, setName] = useState<string>("");
  const [percent, setPercent] = useState<number>(0);
  const [value, setValue] = useState<number>(0);
  const [freeFreight, setFreeFreight] = useState<boolean>(false);
  const [minValue, setMinValue] = useState<number>(0);
  const [maxUsage, setMaxUsage] = useState<number>(0);
  const [type, setType] = useState(coupomTypes.percent);
  const [description, setDescription] = useState("");
  const [allowedFor, setAllowedFor] = useState("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [clusterBlackList, setClusterBlackList] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const cleanFields = () => {
    setName("");
    setPercent(0);
    setValue(0);
    setFreeFreight(false);
    setMinValue(0);
    setMaxUsage(0);
    setType(0);
    setDescription("");
    setAllowedFor("");
    setEndDate("");
    setStartDate("");
  };

  const handleSubmit = async (event: any) => {
    setError("");
    setLoading(true);
    event.preventDefault();

    try {
      await createCoupon({
        cup_st_codigo: name,
        cup_de_porcentagem: percent === 0 ? null : percent,
        cup_de_valor: value === 0 ? null : value,
        cup_bo_fretegratis: freeFreight,
        cup_de_minvalue: minValue === 0 ? null : minValue,
        cup_in_usomaximo: maxUsage === 0 ? null : maxUsage,
        cup_st_descricao: description,
        cup_st_allowedFor: allowedFor,
        cup_dt_startDate: startDate,
        cup_dt_endDate: endDate,
        blacklistedClusters: clusterBlackList,
      });

      cleanFields();
      setError("");
    } catch (error: any) {
      setError("Erro ao criar cupom");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type === coupomTypes.percent) {
      setValue(0);
      setFreeFreight(false);
    }
    if (type === coupomTypes.fixed) {
      setPercent(0);
      setFreeFreight(false);
    }
    if (type === coupomTypes.freight) {
      setFreeFreight(true);
      setValue(0);
      setPercent(0);
    }
  }, [type]);

  return (
    <section className="wrapper">
      <form onSubmit={(event) => handleSubmit(event)}>
        <InputContainer style={{ marginTop: "48px" }}>
          <Label>Nome do cupom</Label>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </InputContainer>

        <InputContainer>
          <Label>Descrição</Label>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </InputContainer>

        <InputContainer>
          <Label>Tipo</Label>
          <Select onChange={(e) => setType(Number(e.target.value))}>
            <option value={coupomTypes.percent}>Porcentagem</option>
            <option value={coupomTypes.fixed}>Valor fixo</option>
            <option value={coupomTypes.freight}>Frete grátis</option>
          </Select>
        </InputContainer>

        <InputContainer>
          <Label>Porcentagem de desconto</Label>
          <Input
            value={percent.toString()}
            onChange={(e) => setPercent(Number(e.target.value))}
            type="number"
            disabled={type !== coupomTypes.percent}
          />
        </InputContainer>

        <InputContainer>
          <Label>Valor de desconto</Label>
          <Input
            value={value.toString()}
            onChange={(e) => setValue(Number(e.target.value))}
            type="number"
            disabled={type !== coupomTypes.fixed}
          />
        </InputContainer>

        <InputContainer>
          <Label>Frete grátis</Label>
          <Input
            checked={freeFreight}
            type="checkbox"
            disabled={type !== coupomTypes.freight}
          />
        </InputContainer>

        <InputContainer>
          <Label>Valor mínimo</Label>
          <Input
            value={minValue.toString()}
            onChange={(e) => setMinValue(Number(e.target.value))}
            type="number"
          />
        </InputContainer>

        <InputContainer>
          <Label>Uso máximo</Label>
          <Input
            value={maxUsage.toString()}
            onChange={(e) => setMaxUsage(Number(e.target.value))}
            type="number"
          />
        </InputContainer>

        <InputContainer>
          <Label>Data Início</Label>
          <DatePicker
            value={startDate}
            onChange={(e) => setStartDate((e.target as HTMLInputElement).value)}
          />
        </InputContainer>

        <InputContainer>
          <Label>Data Final</Label>
          <DatePicker
            value={endDate}
            onChange={(e) => setEndDate((e.target as HTMLInputElement).value)}
          />
        </InputContainer>

        <InputContainer>
          <Label>Permitido para: (Use um email ou provedor de email)</Label>
          <Input
            value={allowedFor}
            onChange={(e) =>
              setAllowedFor((e.target as HTMLInputElement).value)
            }
          />
        </InputContainer>

        <InputContainer>
          <Label>Blacklist de copons</Label>
          <ClusterSelect setState={setClusterBlackList} />
        </InputContainer>

        {loading ? (
          <p>Criando cupom...</p>
        ) : (
          <Button disabled={name === "" || loading} type="submit">
            CRIAR
          </Button>
        )}

        {error !== "" && <ErrorText>{error}</ErrorText>}
      </form>
    </section>
  );
}

const Label = styled.p`
  font-size: 1.25rem;
  font-weight: bold;
  display: inline;
  margin-right: 1rem;
`;

const Input = styled.input`
  width: ${(props) => (props.type === "number" ? "75px" : "auto")};
  border-radius: 7px;
  border: ${(props) =>
    props.disabled ? "1px solid #a8a8a8;" : "1px solid #85b074;"};
  color: ${(props) => props.disabled && "#c6c6c6;"};
  font-size: 1rem;
  padding: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
  text-align: flex-start;
`;

const Select = styled.select`
  width: 8.5rem;
  border-radius: 7px;
  border: 1px solid #85b074;
  font-size: 1rem;
  padding: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
  text-align: flex-start;
`;

export const InputContainer = styled.div`
  display: flex;
  width: 500px;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1.5rem;
  background-color: ${(props) => (props.disabled ? "#a7a7a7" : "#85b074")};
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  cursor: ${(props) => (props.disabled ? "normal" : "pointer")};
`;

const ErrorText = styled.p`
  font-size: 1.5rem;
  color: red;
`;
