import axios from "axios";
import configs from "../appConfigs";
import formatProductsFromApiToListItem, {
  FormattedProduct,
} from "../utils/formatProductsFromApiToListItem";
import { toast } from "react-toastify";

export async function getProducts({ sku }: { sku: string }) {
  try {
    const response = await axios.get(
      `${configs.appApiBaseUrl}/produtos/app/sku/${encodeURIComponent(sku)}`
    );

    return formatProductsFromApiToListItem(response.data.data);
  } catch (error: any) {
    toast.error(`Nenhum produto encontrado com sku: ${sku}`);
    return {} as FormattedProduct;
  }
}

export async function getProductBySkuOrName({
  skuOrName,
}: {
  skuOrName: string;
}) {
  try {
    const response = await axios.get(
      `${configs.appApiBaseUrl}/produtos/app/lookup/${encodeURIComponent(
        skuOrName
      )}`
    );

    return formatProductsFromApiToListItem(response.data.data);
  } catch (error: any) {
    toast.error(`Nenhum produto encontrado com sku ou nome: ${skuOrName}`);
    return {} as FormattedProduct;
  }
}

export async function getProductById({ id }: { id: string }) {
  if (!id) return {} as FormattedProduct;

  const response = await axios.get(
    `${configs.appApiBaseUrl}/produtos/app/id/${id}`
  );

  return formatProductsFromApiToListItem(response.data.data);
}
