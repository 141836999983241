import styled from "styled-components";
import { CupomT, OrderT, ValuesT } from "../../../services/types";
import { formatPrice, getDiscount } from "../../../utils/price";
import { Title } from "../FreightDetails/style";

export default function PaymentSummary({
  cupom,
  values,
  order,
}: {
  cupom: CupomT;
  values: ValuesT;
  order: OrderT;
}) {
  const Data = ({ title, text }: { title: string; text: string }) => (
    <TextContainer style={{ flexDirection: "row", width: 1000 }}>
      <BoldText>{title}: </BoldText>
      {text}
    </TextContainer>
  );

  const { produtos } = values;

  const discounts = getDiscount(cupom, produtos, order);

  const total = Number(produtos);

  const totalProdutos = order.produtos.reduce((acc, curr) => {
    acc += Number(curr.preco) * curr.quantidade;
    return acc;
  }, 0);

  return (
    <>
      <Title>Detalhes do pagamento: </Title>
      <Data
        title="Cupom aplicado"
        text={cupom?.cup_st_codigo || "Não Aplicado"}
      />
      <Data title="Descontos" text={formatPrice(Number(discounts))} />
      <Data
        title="Valor total dos produtos"
        text={formatPrice(totalProdutos - Number(discounts))}
      />
      <Data title="Valor total" text={formatPrice(Number(total))} />
    </>
  );
}

const TextContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const BoldText = styled.p`
  font-weight: bold;
  display: inline;
`;
