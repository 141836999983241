import { useState } from "react";
import styled from "styled-components";
import theme from "../styles/colors";
import MenuIconSvg from "./MenuIconSvg";
import MenuItem from "./MenuItem";

export function NavMenu() {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const emailsCX = [
    "rafaela.nascimento@sonoma.com.br",
    "cleciane.oliveira@sonoma.com.br",
    "weslei.andrade@sonoma.com.br",
    "rarime.oliveira@sonoma.com.br",
    "giovanna.silva@sonoma.com.br",
    "isabela.lima@sonoma.com.br",
    "thiago.oliveira@sonoma.com.br",
  ];

  const storedEmail = localStorage.getItem("Email");

  function toggleMenu() {
    setIsOpen((state) => !state);
  }

  return (
    <>
      <Button onClick={toggleMenu}>
        <MenuIconSvg />
      </Button>
      {storedEmail && emailsCX.includes(storedEmail) ? (
        <>
          <MenuItem to={"/cx/orders"} title={"Pedidos"} />
          <MenuItem to={"/cx/couponsList"} title={"Cupons"} />
          <MenuItem to={"/cx/freight"} title={"Frete"} />
          <Nav isOpen={isOpen}>
            <Button onClick={toggleMenu} className={`closeButton`}>
              Fechar
            </Button>
            <MenuItem to={"/cx/couponsList"} title={"Cupons"} />
            <MenuItem to={"/cx/freight"} title={"Frete"} />
            <MenuItem to={"/cx/orders"} title={"Pedidos"} />
          </Nav>
        </>
      ) : (
        <>
          <MenuItem to={"/dashboard"} title={"Relatórios"} />
          <MenuItem to={"/banners"} title={"Banners"} />
          <MenuItem to={"/orders"} title={"Pedidos"} />
          <MenuItem to={"/clustersList"} title={"Coleções"} />
          <MenuItem to={"/couponsList"} title={"Cupons"} />
          <Nav isOpen={isOpen}>
            <Button onClick={toggleMenu} className={`closeButton`}>
              Fechar
            </Button>
            <MenuItem to={"/dashboard"} title={"Relatórios"} />
            <MenuItem to={"/audit/orders"} title={"Auditoria"} />
            <MenuItem to={"/banners"} title={"Banners"} />
            <MenuItem to={"/categories"} title={"Categorias"} />
            <MenuItem to={"/clustersList"} title={"Coleções"} />
            <MenuItem to={"/couponsList"} title={"Cupons"} />
            {/* <MenuItem to={"/debug"} title={"Debugger"} /> */}
            <MenuItem to={"/freight"} title={"Frete"} />
            <MenuItem to={"/kits"} title={"Kits"} />
            <MenuItem to={"/orders"} title={"Pedidos"} />
            {/* <MenuItem to={"/splitted"} title={"Pedidos Splitted"} /> */}
            <MenuItem
              to={"/restrictions"}
              title={"Regionalização de produtos"}
            />
            {/* <MenuItem */}
            {/*   to={"/promotional_products"} */}
            {/*   title={"Produtos promocionais"} */}
            {/* /> */}
            {/* <MenuItem */}
            {/*   to={"/promotions/shipping/all"} */}
            {/*   title={"Fretes promocionais"} */}
            {/* /> */}
            <MenuItem to={"/reports/signature"} title={"Signature Dashboard"} />
            <MenuItem to={"/signature"} title={"Signature App"} />
            <MenuItem to={"/dictionary"} title={"Dicionário"} />
            <MenuItem to={"/createLink"} title={"Criar Link"} />
            <MenuItem to={"/sellerCenter"} title={"Seller Center"} />
            <MenuItem to={"/sales/dashboard"} title={"Dashboard De Vendas"} />
            <MenuItem to={"/client/carts"} title={"Carrinhos Abandonados"} />
            <MenuItem to={"/clients"} title={"Clientes"} />
            <MenuItem to={"/strip"} title={"Gerenciar Tarja"} />
          </Nav>
        </>
      )}
    </>
  );
}

const Button = styled.button`
  border: none;
  background: none;
  border-radius: 0;
  height: 2.5rem;
  padding: 0 2rem;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text_dark};
  font-weight: 600;
`;

const Nav = styled.nav<{ isOpen?: boolean }>`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.background_light};
  box-shadow: 5px 0px 12px -1px rgba(0, 0, 0, 0.05);
  width: ${(props) => (props.isOpen ? "10%" : 0)};
  min-width: ${(props) => (props.isOpen ? "250px" : 0)};
  height: 100vh;
  transition: all 0.2s;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0.25em;
  }

  &::-webkit-scrollbar-track {
    ${theme.colors.background_light}
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background_dark};
  }

  .closeButton {
    width: 100%;
    height: 3rem;
    min-height: 48px;
    font-size: 24px;
    text-align: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.text_dark};
    margin-bottom: 1rem;
  }
`;
