import axios, { AxiosError } from "axios";
import configs from "../appConfigs";
import { toast } from "react-toastify";
import { formatedCollectionItem } from "./types";

export async function getCollections(): Promise<Array<formatedCollectionItem>> {
  const colections = await axios.get(
    `${configs.appApiBaseUrl}/produtos/app/listClusters`
  );

  return colections.data.data.map(
    (col: { clu_in_codigo: number; clu_st_nome: string }) => ({
      title: col.clu_st_nome,
      value: col.clu_in_codigo,
    })
  );
}
export async function getCollectionsAndCount() {
  const colections = await axios.get(`${configs.apiBaseUrl}/api/cluster`);

  return colections.data;
}

export async function createCollection({
  clu_st_nome,
  clu_st_url,
  products,
}: {
  clu_st_nome: string;
  clu_st_url: string;
  products: string[];
}) {
  try {
    await axios.post(`${configs.apiBaseUrl}/api/cluster`, {
      clu_st_nome,
      clu_st_url,
      products,
    });

    toast.success("Coleção criada com sucesso!");
  } catch (error) {
    toast.error("Erro ao criar coleção.");
  }
}

export async function addProductToCollection(
  clusterId: number,
  productId: string,
  order: number
) {
  try {
    await axios.put(`${configs.apiBaseUrl}/api/addProductToCluster`, {
      clusterId,
      productId,
      order: `${order}`,
    });

    toast.success("Produto adicionado à coleção!");
  } catch (error: any | AxiosError) {
    const errorMessage =
      error instanceof AxiosError
        ? error.response?.data.message?.message
        : "Erro ao adicionar produto";
    toast.error(errorMessage);
  }
}

export async function listProductsByClusterId(
  clusterId: number,
  limit?: number,
  page?: number,
  order?: string,
  noCache?: boolean
) {
  try {
    const response = await axios.get(
      `${
        configs.apiBaseUrl
      }/api/cluster/${clusterId}?limit=${(limit = 2000)}&page=${(page = 1)}&order=${(order =
        "asc")}&noCache=${(noCache = true)}`
    );

    const products = response?.data?.data;

    return products;
  } catch (error) {
    return [];
  }
}

export async function removeClusterAndBanner(clusterId: number) {
  try {
    await axios.delete(`${configs.apiBaseUrl}/api/cluster/${clusterId}`);

    toast.success("Coleção deletada!");
  } catch (error) {
    toast.error("Erro ao deletar coleção.");
  }
}

export async function removeProductFromCluster(
  clusterId: number,
  productId: number
) {
  try {
    await axios.post(`${configs.apiBaseUrl}/api/removeProductFromCluster`, {
      clusterId,
      productId,
    });
    toast.success("Produto removido da coleção!");
  } catch (error) {
    toast.error("Erro ao remover produto.");
  }
}

export async function putCollection({
  clu_in_codigo,
  clu_st_url,
}: {
  clu_in_codigo: number;
  clu_st_url: string;
}) {
  try {
    await axios.put(`${configs.apiBaseUrl}/admin/colecoes/${clu_in_codigo}`, {
      clu_st_url,
    });
    toast.success("Coleção alterada com sucesso!");
  } catch (error) {
    toast.error("Erro ao alterar coleção.");
  }
}
