import styled from "styled-components";
import { FormattedCategory } from "../services/types";

export interface FlattenedCategory {
  id: number;
  description: string;
  path: string;
}

const flattenCategories = (
  categories: FormattedCategory[],
  path?: string
): FlattenedCategory[] => {
  return categories.reduce<FlattenedCategory[]>((acc, category) => {
    const { childrenCategories, id, description } = category;
    const fullPath = `${path} > ${description}`;

    acc.push({ id, description, path: fullPath });

    if (childrenCategories?.length) {
      acc.push(...flattenCategories(childrenCategories, fullPath));
    }

    return acc;
  }, []);
};

export default function CategoryPicker({
  categories,
  setChosenCategory,
  selectedCategory,
  filter = "",
}: {
  categories: FormattedCategory[];
  setChosenCategory: React.Dispatch<
    React.SetStateAction<FlattenedCategory | null>
  >;
  selectedCategory: FlattenedCategory | null;
  filter?: string;
}) {
  const flattenedCategories = flattenCategories(categories, "Todos");

  const handlePickCategory = (category: FlattenedCategory) => {
    if (selectedCategory?.id === category.id) {
      setChosenCategory(null);
      return;
    }
    setChosenCategory(category);
  };

  return (
    <CategoriesContainer>
      <ul>
        {flattenedCategories
          .filter(
            (category) =>
              category.description
                ?.toLowerCase()
                .includes(filter.toLowerCase()) ||
              selectedCategory?.id === category.id
          )
          .map((category) => {
            if (!category.description.length) return null;

            return (
              <CategoryListitem
                $isSelected={selectedCategory?.id === category.id}
                key={category.id}
                onClick={() => handlePickCategory(category)}
              >
                <small>{category.path.toLowerCase()}</small>
              </CategoryListitem>
            );
          })}
      </ul>
    </CategoriesContainer>
  );
}

const CategoryListitem = styled.li<{ $isSelected: boolean }>`
  list-style: none;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.2rem;
  padding: 0.5rem;
  background-color: ${(props) => (props.$isSelected ? "#6d9962" : "#fff")};
  border: 1px solid #6d9962;

  small {
    padding: 0.5rem;
    color: ${(props) =>
      props.$isSelected ? "#fff" : props.theme.colors.text_dark};
    text-transform: capitalize;
    user-select: none;
  }

  &:hover {
    background-color: #6d9962;
    small {
      color: #fff;
    }
  }
`;

const CategoriesContainer = styled.div`
  width: 31.25rem;
  height: 45vh;
  overflow: hidden;
  overflow-y: scroll;
`;
